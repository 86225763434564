import './index.html';
import './about.html';
import "./style.scss";

import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';

const swiper = new Swiper('.posts-slider', {
    modules: [ Navigation, Pagination ],
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: true,
    centerSlide: true,
    fade: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      818: {
        slidesPerView: 3,
        spaceBetween: 27,
      },
    },
  });

const ourPartners = new Swiper('.our-partners', {
  modules: [ Navigation, Pagination ],
  slidesPerView: 1,
  // spaceBetween: 24,
  grabCursor: true,
  centerSlide: true,
  fade: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    600: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    818: {
      slidesPerView: 1,
      spaceBetween: 27,
    },
  },
});

const accordionContent = document.querySelectorAll('.accordion-content');
accordionContent.forEach((item, index) => {
  let header = item.querySelector('.accordion-content-header');
  header.addEventListener('click', () => {
    item.classList.toggle('open');

    let description = item.querySelector('.accordion-content-description');
    if(item.classList.contains('open')) {
      description.style.height = `${description.scrollHeight}px`;
    } else {
      description.style.height = `0px`;
    }
  })
});